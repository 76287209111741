/* eslint-disable array-callback-return */
/* eslint-disable no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect, Fragment } from "react";
import {
  Box,
  Button,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  FormControlLabel,
  Checkbox,
  ListItemIcon,
  ListItemText,
  Stepper,
  Card,
  Step,
  StepButton,
} from "@mui/material";
import { isEmpty } from "lodash";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DesktopDatePicker } from "@mui/x-date-pickers";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { TextValidator, ValidatorForm } from "react-material-ui-form-validator";
import { connect, useDispatch } from "react-redux";
import { bindActionCreators } from "redux";
import moment from "moment-timezone";
import PhoneInput from "react-phone-input-2";
import ENVIRONMENT_VARIABLES from "../../../environment.config";
import * as CustomerAction from "../../../Action/Customer";
import {
  ERROR,
  INPROGRESS,
  STOPLOADER,
} from "../../../utils/Customer/Constant";
import "./style.scss";
import BookingSummary from "./BookingSummary";
import { QontoConnector } from "../HotelReservation";
import { saluation } from "../HotelReservation";
import BookingConfirmation from "../BookingConfirmation";

const privateEventBookingSteps = [
  "Select Reservation Type ",
  "Select Date And Time",
  "Confirm Details",
  "Customer Details",
  "Booking Summary",
];

const PrivateEventBooking = (props) => {
  const dispatch = useDispatch();
  const [activeStep, setActiveStep] = useState(1);
  const [completed, setCompleted] = useState({ 0: true });
  const [invoice, setInvoice] = useState(null);
  const [dietRestriction, setDietRestriction] = useState([]);
  const [occasion, setOccasion] = useState([]);
  const [timeSlots, setTimeSlots] = useState(null);
  const [stripePayment, setStripePayment] = useState(null);
  const [openConfirmation, setOpenConfirmation] = useState(false);
  const [eventBooking, setEventBooking] = useState({
    bookingType: "TICKETING_EVENT",
    date: new Date(),
    noOfAdult: null,
    noOfChild: null,
    exactTime: "",
    name: "",
    lastName: "",
    customerCompanyName: "",
    email: "",
    mobileNo: "",
    salutation: "Ms",
    occasion: "",
    dietaryRestriction: [],
    isOPT: false,
  });

  useEffect(() => {
    const categoryIds = [
      ENVIRONMENT_VARIABLES.Base_DIETARY_RESTRICTION,
      ENVIRONMENT_VARIABLES.Base_OCCASION,
    ];

    categoryIds.forEach((category) => {
      dispatch({ type: INPROGRESS });
      CustomerAction.getTagsByOutletCategory(category, props.event.outletId)
        .then((response) => {
          if (response.status === 200) {
            dispatch({ type: STOPLOADER });
            if (category === ENVIRONMENT_VARIABLES.Base_OCCASION) {
              setOccasion([
                { name: "None", value: "" },
                ...response.data.data.map((data) => ({
                  ...data,
                  value: data.name,
                })),
              ]);
            } else {
              const mappedTag = response.data.data.map((data) => ({
                ...data,
                isChecked: false,
              }));
              setDietRestriction(mappedTag);
            }
          }
        })
        .catch((error) => {
          dispatch({
            type: ERROR,
            data: { error_msg: error.response?.data?.message || error.message },
          });
        });
    });
  }, [dispatch, props.event.outletId]);

  const completedSteps = () => Object.keys(completed).length;
  const totalSteps = () => privateEventBookingSteps.length;
  const isLastStep = () => activeStep === totalSteps() - 1;
  const allStepsCompleted = () => completedSteps() === totalSteps();

  const handleComplete = () => {
    const newCompleted = { ...completed };
    newCompleted[activeStep] = true;
    setCompleted(newCompleted);
    handleNext();
  };

  const handleNext = () => {
    const newActiveStep =
      isLastStep() && !allStepsCompleted()
        ? privateEventBookingSteps.findIndex((step, i) => !(i in completed))
        : activeStep + 1;
    if (isLastStep()) {
      handleSubmit();
    } else {
      setActiveStep(newActiveStep);
    }
  };

  const handleChange = (event) => {
    const { name, value } = event.target;
    setEventBooking((prev) => ({ ...prev, [name]: value }));

    if (name === "noOfAdult" && value > 0 && eventBooking.date) {
      getEventTimeslot({ ...eventBooking, [name]: value });
    }
  };

  const getEventTimeslot = (bookingData) => {
    dispatch({ type: INPROGRESS });
    CustomerAction.getEventTimeSlot(
      {
        ...bookingData,
        date: moment(bookingData.date).format("DD-MM-YYYY"),
      },
      props.event.id
    )
      .then((response) => {
        if (response.status === 200 && response.data.data) {
          const availableTimeSlots = response.data.data;
          if (availableTimeSlots.length > 0) {
            setTimeSlots(availableTimeSlots);
            setEventBooking((prev) => ({
              ...prev,
              exactTime: availableTimeSlots[0],
            }));
            dispatch({ type: STOPLOADER });
          } else {
            dispatch({
              type: ERROR,
              data: {
                error_msg:
                  "Sorry, this date is not available. Please try another date",
              },
            });
          }
        }
      })
      .catch((error) => {
        dispatch({
          type: ERROR,
          data: { error_msg: error.response?.data?.message || error.message },
        });
      });
  };

  const handleSubmit = () => {
    const filterDietaryRestriction = eventBooking.dietaryRestriction
      .filter((data) => data.isChecked)
      .map((diet) => diet.name);

    const data = {
      ...eventBooking,
      mobileNo: `+${eventBooking.mobileNo}`,
      date: moment(eventBooking.date).format("DD-MM-YYYY"),
      dietaryRestriction: filterDietaryRestriction,
    };

    dispatch({ type: INPROGRESS });

    CustomerAction.EventBookingTicket(data, props.event.id)
      .then((response) => {
        if (response.status === 201) {
          setInvoice(response.data.data);
          setActiveStep({ 0: true });
          setOpenConfirmation(true);
          dispatch({ type: STOPLOADER });
        } else if (response.status === 200) {
          setStripePayment(response.data.data.client_secret);
          dispatch({ type: STOPLOADER });
        }
      })
      .catch((error) => {
        dispatch({
          type: ERROR,
          data: { error_msg: error.response?.data?.message || error.message },
        });
      });
  };

  const handleCloseConfirmationBooking = () => {
    setTimeSlots(null);
    setEventBooking({
      bookingType: "TICKETING_EVENT",
      date: new Date(),
      noOfAdult: 0,
      noOfChild: 0,
      exactTime: "",
      name: "",
      lastName: "",
      customerCompanyName: "",
      email: "",
      mobileNo: "",
      salutation: "Ms",
      occasion: "",
      dietaryRestriction: [],
      specialRequest: "",
      isOPT: false,
    });
  };

  const handleChangeDate = (date) => {
    setTimeSlots(null);
    setEventBooking((prev) => ({ ...prev, date }));
    if (eventBooking.noOfAdult > 0) {
      getEventTimeslot({ ...eventBooking, date });
    }
  };

  const handleFilter = (e) => {
    const value = e.target.value[e.target.value.length - 1];
    const updatedDietRestriction = dietRestriction.map((data) =>
      data.id === value ? { ...data, isChecked: !data.isChecked } : data
    );
    setDietRestriction(updatedDietRestriction);
    setEventBooking((prev) => ({
      ...prev,
      dietaryRestriction: updatedDietRestriction,
    }));
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
    const newCompleted = completed;
    newCompleted[activeStep] = false;
    setCompleted(newCompleted);
  };

  const calculateTotalAmount = () => {
    const totalAmount =
      eventBooking.noOfAdult * props.event.amountPerAdult +
      eventBooking.noOfChild * props.event.amountPerChild;
    return totalAmount;
  };

  const calculateDepositAmount = () => {
    const depositAmount =
      eventBooking.noOfAdult * props.event.depositPerAdult +
      eventBooking.noOfChild * props.event.depositPerChild;
    return depositAmount;
  };

  const handleClosePaymentPopup = () => {
    setStripePayment(null);
  };

  const renderStepContent = (step) => {
    switch (step) {
      case 1:
        return (
          <ValidatorForm
            onSubmit={handleComplete}
            className="main-div"
            autoComplete="off"
          >
            <div className="stepper-two">
              <div className="stepper-two-left">
                <h1 className="stepper-header-text">{props.event.name}</h1>
                <div className="date-picker-div">
                  {props.event && (
                    <img
                      src={`${ENVIRONMENT_VARIABLES.Base_IMAGE_URL}${props.event.image}`}
                      alt="imgs"
                    />
                  )}
                  <h2>{props.event.name}</h2>
                </div>
              </div>

              <div className="right-side">
                <div className="right-side-inner">
                  <div className="restaurant-card">
                    <div className="restaurant-card-inner">
                      <span className="card-name">{props.event.name}</span>
                    </div>
                  </div>

                  <div
                    className="input-box"
                    style={{ display: "flex", gap: "10px" }}
                  >
                    <div className="input-box-inner w-50">
                      <TextValidator
                        fullWidth
                        label="No Of Adult"
                        type="number"
                        name="noOfAdult"
                        size="small"
                        value={eventBooking.noOfAdult}
                        onChange={handleChange}
                        validators={["required", "minNumber:1"]}
                        errorMessages={[
                          "Pax is required",
                          "Pax should be more than 1",
                        ]}
                      />
                    </div>
                    <div className="input-box-inner w-50">
                      <TextValidator
                        fullWidth
                        label="No Of Child"
                        type="number"
                        name="noOfChild"
                        size="small"
                        value={eventBooking.noOfChild}
                        onChange={handleChange}
                        validators={[ "minNumber:0" ]}
                        errorMessages={[
                          "No. of Child should be valid",
                        ]}  
                      />
                    </div>
                  </div>

                  <div className="input-box">
                    <div className="input-box-inner date-picker1">
                      <LocalizationProvider
                        dateAdapter={AdapterDayjs}
                        size="small"
                      >
                        <DesktopDatePicker
                          disablePast
                          label="Date"
                          name="date"
                          value={eventBooking.date}
                          onChange={(newValue) => {
                            handleChangeDate(new Date(newValue));
                          }}
                          inputFormat="DD-MM-YYYY"
                          renderInput={(params) => <TextField {...params} />}
                          maxDate={props.event.endDate}
                          minDate={props.event.startDate}
                        />
                      </LocalizationProvider>
                    </div>
                  </div>

                  <div className="input-box">
                    <div className="input-box-inner">
                      {timeSlots && (
                        <FormControl fullWidth>
                          <label class="input">
                            <select
                              labelId="timeSlots"
                              size="small"
                              value={eventBooking.timeSlots}
                              label="timeSlots"
                              type="text"
                              name="exactTime"
                              inputProps={{ "aria-label": "Without label" }}
                              onChange={handleChange}
                              className="input__field"
                            >
                              {timeSlots.map((data, index) => (
                                <option key={index} value={data}>
                                  {data}
                                </option>
                              ))}
                            </select>
                            <span class="input__label">TimeSlots</span>
                          </label>
                        </FormControl>
                      )}
                    </div>
                  </div>

                  {props.event && (
                    <div className="input-box">
                      <div className="input-box-inner">
                        <p>Price Per Child ${props.event.amountPerChild}</p>
                        <p>Price Per Adult ${props.event.amountPerAdult}</p>
                      </div>
                    </div>
                  )}
                </div>

                <Box className="footer-btn">
                  <Button
                    variant="contained"
                    className="inner-btn"
                    color="inherit"
                    onClick={props.handleBack}
                    sx={{ mr: 1 }}
                  >
                    Back
                  </Button>
                  <Button type="submit"
                    disabled={isEmpty(timeSlots)}
                    sx={{ mr: 1 }} variant="contained">
                    Next
                  </Button>
                </Box>
              </div>
            </div>
          </ValidatorForm>
        );
      case 2:
        return (
          <ValidatorForm className="main-div" onSubmit={handleComplete}>
            <div className="stepper-two stepper-four">
              <div className="stepper-four-left">
                <h1 className="stepper-header-text">{props.event.name}</h1>
                <div className="date-picker-div">
                  {props.event && (
                    <img
                      src={`${ENVIRONMENT_VARIABLES.Base_IMAGE_URL}${props.event.image}`}
                      alt="imgs"
                    />
                  )}
                  <h2>{props.event.name}</h2>
                </div>
              </div>

              {props.event && (
                <div className="right-side">
                  <div className="right-side-inner">
                  <h2>{props.event.name}</h2>
                    <div className="accordion-div expandedtrue">
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "column",
                          gap: "10px",
                          padding: "0",
                        }}
                      >
                        <div className="restaurant-info">
                          <span className="restaurant-text-inner">Date</span>
                          <span className="restaurant-text-inner">
                            {moment(eventBooking.date).format("DD-MM-YYYY")}
                          </span>
                        </div>

                        <div className="restaurant-info">
                          <span className="restaurant-text-inner">
                            Reservation Time
                          </span>
                          <span className="restaurant-text-inner">
                            {eventBooking.exactTime}
                          </span>
                        </div>

                        <div className="restaurant-info">
                          <span className="restaurant-text-inner">
                            No. of Adult
                          </span>
                          <span className="restaurant-text-inner">
                            {eventBooking.noOfAdult}
                          </span>
                        </div>
                        {eventBooking.noOfChild > 0 && (
                          <div className="restaurant-info">
                            <span className="restaurant-text-inner">
                              No. of Child
                            </span>
                            <span className="restaurant-text-inner">
                              {eventBooking.noOfChild}
                            </span>
                          </div>
                        )}

                        <div
                          className="operating-time"
                          style={{ marginTop: "20px", gap: "20px" }}
                        >
                          <div
                            style={{ display: "flex", flexDirection: "column" }}
                          >
                            <div className="restaurant-info">
                              <div
                                className="restaurant-text-inner time-text-color"
                                style={{ width: "60%", alignItems: "end" }}
                              >
                                <span
                                  className="restaurant-text-inner"
                                  style={{
                                    position: "sticky",
                                    top: "0",
                                    zIndex: "10",
                                    backgroundColor: "#FFF1E4",
                                  }}
                                >
                                  Total Amount
                                </span>
                              </div>
                              <div className="restaurant-text-inner time-text-color">
                                <span
                                  className="time-text"
                                  style={{
                                    position: "sticky",
                                    top: "0",
                                    zIndex: "10",
                                    backgroundColor: "#FFF1E4",
                                    fontWeight: "700",
                                  }}
                                >
                                  ${calculateTotalAmount()}
                                </span>
                              </div>
                            </div>

                            <div className="restaurant-info">
                              <div
                                className="restaurant-text-inner time-text-color"
                                style={{ width: "60%", alignItems: "end" }}
                              >
                                <span
                                  className="restaurant-text-inner"
                                  style={{
                                    position: "sticky",
                                    top: "0",
                                    zIndex: "10",
                                    backgroundColor: "#FFF1E4",
                                  }}
                                >
                                  Adult ${props.event.amountPerAdult} x
                                  {eventBooking.noOfAdult}
                                </span>
                              </div>
                              <div className="restaurant-text-inner time-text-color">
                                <span
                                  className="time-text"
                                  style={{
                                    position: "sticky",
                                    top: "0",
                                    zIndex: "10",
                                    backgroundColor: "#FFF1E4",
                                    fontWeight: "700",
                                  }}
                                >
                                  $
                                  {eventBooking.noOfAdult *
                                    props.event.amountPerAdult}
                                </span>
                              </div>
                            </div>

                            {eventBooking.noOfChild > 0 && (
                              <div className="restaurant-info">
                                <div
                                  className="restaurant-text-inner time-text-color"
                                  style={{ width: "60%", alignItems: "end" }}
                                >
                                  <span
                                    className="restaurant-text-inner"
                                    style={{
                                      position: "sticky",
                                      top: "0",
                                      zIndex: "10",
                                      backgroundColor: "#FFF1E4",
                                    }}
                                  >
                                    Child ${props.event.amountPerChild} x
                                    {eventBooking.noOfChild}
                                  </span>
                                </div>
                                <div className="restaurant-text-inner time-text-color">
                                  <span
                                    className="time-text"
                                    style={{
                                      position: "sticky",
                                      top: "0",
                                      zIndex: "10",
                                      backgroundColor: "#FFF1E4",
                                      fontWeight: "700",
                                    }}
                                  >
                                    $
                                    {eventBooking.noOfChild *
                                      props.event.amountPerChild}
                                  </span>
                                </div>
                              </div>
                            )}
                          </div>

                          <div
                            style={{ display: "flex", flexDirection: "column" }}
                          >
                            <div className="restaurant-info">
                              <div
                                className="restaurant-text-inner time-text-color"
                                style={{ width: "60%", alignItems: "end" }}
                              >
                                <span
                                  className="restaurant-text-inner"
                                  style={{
                                    position: "sticky",
                                    top: "0",
                                    zIndex: "10",
                                    backgroundColor: "#FFF1E4",
                                  }}
                                >
                                  Total Deposit Amount Required
                                </span>
                              </div>
                              <div className="restaurant-text-inner time-text-color">
                                <span
                                  className="time-text"
                                  style={{
                                    position: "sticky",
                                    top: "0",
                                    zIndex: "10",
                                    backgroundColor: "#FFF1E4",
                                    fontWeight: "700",
                                  }}
                                >
                                  ${calculateDepositAmount()}
                                </span>
                              </div>
                            </div>

                            <div className="restaurant-info">
                              <div
                                className="restaurant-text-inner time-text-color"
                                style={{ width: "60%", alignItems: "end" }}
                              >
                                <span
                                  className="restaurant-text-inner"
                                  style={{
                                    position: "sticky",
                                    top: "0",
                                    zIndex: "10",
                                    backgroundColor: "#FFF1E4",
                                  }}
                                >
                                  Adult ${props.event.depositPerAdult} x
                                  {eventBooking.noOfAdult}
                                </span>
                              </div>
                              <div className="restaurant-text-inner time-text-color">
                                <span
                                  className="time-text"
                                  style={{
                                    position: "sticky",
                                    top: "0",
                                    zIndex: "10",
                                    backgroundColor: "#FFF1E4",
                                    fontWeight: "700",
                                  }}
                                >
                                  $
                                  {eventBooking.noOfAdult *
                                    props.event.depositPerAdult}
                                </span>
                              </div>
                            </div>

                            {eventBooking.noOfChild > 0 && (
                              <div className="restaurant-info">
                                <div
                                  className="restaurant-text-inner time-text-color"
                                  style={{ width: "60%", alignItems: "end" }}
                                >
                                  <span
                                    className="restaurant-text-inner"
                                    style={{
                                      position: "sticky",
                                      top: "0",
                                      zIndex: "10",
                                      backgroundColor: "#FFF1E4",
                                    }}
                                  >
                                    Child ${props.event.depositPerChild} x
                                    {eventBooking.noOfChild}
                                  </span>
                                </div>
                                <div className="restaurant-text-inner time-text-color">
                                  <span
                                    className="time-text"
                                    style={{
                                      position: "sticky",
                                      top: "0",
                                      zIndex: "10",
                                      backgroundColor: "#FFF1E4",
                                      fontWeight: "700",
                                    }}
                                  >
                                    $
                                    {eventBooking.noOfChild *
                                      props.event.depositPerChild}
                                  </span>
                                </div>
                              </div>
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <Box className="footer-btn">
                    <Button
                      variant="contained"
                      className="inner-btn"
                      color="inherit"
                      onClick={handleBack}
                      sx={{ mr: 1 }}
                    >
                      Back
                    </Button>
                    <Button type="submit" sx={{ mr: 1 }} variant="contained">
                      Next
                    </Button>
                  </Box>
                </div>
              )}
            </div>
          </ValidatorForm>
        );
      case 3:
        return (
          <ValidatorForm
            className="main-div"
            onSubmit={handleComplete}
            autoComplete="off"
          >
            <div className="main">
              <div className="main-body">
                <div className="privateEvent-booking">
                  <div className="condition-info">
                    <span className="conditions-info-text w-100">
                      Conditions
                    </span>
                    <div className="conditions w-100">
                      <span
                        style={{ whiteSpace: "pre-wrap" }}
                        className="conditions-text"
                      >
                        {props.company.paymentTC}
                      </span>
                    </div>
                  </div>
                  <h3 className="customer-header conditions-text w-100 center-text">
                    {props.event.name}
                  </h3>

                  <div className="conditions-form">
                    <div className="input-box">
                      <div className="input-box-inner">
                        <TextValidator
                          fullWidth
                          label="First Name"
                          type="text"
                          name="name"
                          size="small"
                          value={eventBooking.name}
                          onChange={handleChange}
                          validators={["required"]}
                          errorMessages={["First Name is required"]}
                        />
                      </div>
                    </div>

                    <div className="input-box">
                      <div className="input-box-inner">
                        <TextValidator
                          fullWidth
                          label="Last Name"
                          type="text"
                          name="lastName"
                          size="small"
                          value={eventBooking.lastName}
                          onChange={handleChange}
                          validators={["required"]}
                          errorMessages={["Last Name is required"]}
                        />
                      </div>
                    </div>

                    <div className="input-box">
                      <div className="input-box-inner">
                        <TextValidator
                          fullWidth
                          label="Company Name"
                          type="text"
                          name="customerCompanyName"
                          size="small"
                          value={eventBooking.customerCompanyName}
                          onChange={handleChange}
                        />
                      </div>
                    </div>

                    <div className="input-box">
                      <div className="input-box-inner">
                        <TextValidator
                          label="Email"
                          type="email"
                          name="email"
                          size="small"
                          fullWidth
                          value={eventBooking.email}
                          onChange={handleChange}
                          validators={["required", "isEmail"]}
                          errorMessages={[
                            "Email is required",
                            "email is not valid",
                          ]}
                        />
                      </div>
                    </div>

                    <div className="input-box">
                      <div className="input-box-inner">
                        <FormControl
                          fullWidth
                          size="small"
                          sx={{ width: "160px" }}
                        >
                          <label class="input">
                            <select
                              required
                              labelId="salutation"
                              size="small"
                              value={eventBooking.salutation}
                              label="Salutation"
                              type="text"
                              name="salutation"
                              inputProps={{ "aria-label": "Without label" }}
                              onChange={handleChange}
                              className="input__field"
                            >
                              {saluation.map((data, index) => (
                                <option key={index} value={data.name}>
                                  {data.name}
                                </option>
                              ))}
                            </select>
                            <span class="input__label">Salutation</span>
                          </label>
                        </FormControl>
                      </div>
                    </div>

                    <div className="input-box">
                      <div className="input-box-inner">
                        <PhoneInput
                          className="w-100"
                          country={"sg"}
                          inputProps={{
                            name: "phone",
                            required: true,
                          }}
                          onChange={(phone) =>
                            handleChange({
                              target: { name: "mobileNo", value: phone },
                            })
                          }
                          value={eventBooking.mobileNo}
                        />
                      </div>
                    </div>

                    {occasion.length > 0 && (
                      <div className="input-box">
                        <div className="input-box-inner">
                          <FormControl
                            fullWidth
                            size="small"
                            sx={{ width: "160px" }}
                          >
                            <label class="input">
                              <select
                                labelId="occasion"
                                size="small"
                                value={eventBooking.occasion}
                                name="occasion"
                                label="occasion"
                                inputProps={{
                                  "aria-label": "Without label",
                                }}
                                onChange={handleChange}
                                className="input__field"
                              >
                                {occasion.map((data, index) => (
                                  <option key={index} value={data.value}>
                                    {data.name}
                                  </option>
                                ))}
                              </select>
                              <span class="input__label">Occasion</span>
                            </label>
                          </FormControl>
                        </div>
                      </div>
                    )}

                    {dietRestriction.length > 0 && (
                      <div className="input-box">
                        <div className="input-box-inner">
                          <FormControl
                            fullWidth
                            size="small"
                            sx={{ width: "160px" }}
                          >
                            <InputLabel id="occasion">
                              Dietary Restriction
                            </InputLabel>
                            <Select
                              multiple
                              labelId="dietaryRestriction"
                              size="small"
                              value={eventBooking.dietaryRestriction}
                              name="dietaryRestriction"
                              label="dietaryRestriction"
                              inputProps={{ "aria-label": "Without label" }}
                              onChange={handleFilter}
                              renderValue={(selected) => {
                                selected =
                                  eventBooking.dietaryRestriction.filter(
                                    (data) => data.isChecked === true
                                  );
                                const renderData = selected.map(
                                  (user) => user.name
                                );
                                return renderData.join(", ");
                              }}
                            >
                              {dietRestriction.map((data) => (
                                <MenuItem key={data.id} value={data.id}>
                                  <ListItemIcon>
                                    <Checkbox checked={data.isChecked} />
                                  </ListItemIcon>
                                  <ListItemText primary={data.name} />
                                </MenuItem>
                              ))}
                            </Select>
                          </FormControl>
                        </div>
                      </div>
                    )}

                    <div className="input-box w-100">
                      <div className="input-box-inner">
                        <TextValidator
                          fullWidth
                          label="Special Request"
                          type="text"
                          name="specialRequest"
                          size="small"
                          multiline
                          rows={3}
                          value={eventBooking.specialRequest}
                          onChange={handleChange}
                        />
                      </div>
                    </div>

                    <div className="input-box w-100">
                      <div className="input-box-inner update-checkbox">
                        <FormControlLabel
                          label="Receive updates, news and promotions from Creatives Eateries"
                          control={
                            <Checkbox
                              checked={eventBooking.isOPT}
                              sx={{ padding: "0" }}
                              onChange={() =>
                                setEventBooking({
                                  ...eventBooking,
                                  isOPT: !eventBooking.isOPT,
                                })
                              }
                              inputProps={{ "aria-label": "controlled" }}
                            />
                          }
                        />
                      </div>
                    </div>
                    <Box className="footer-btn">
                      <Button
                        onClick={handleBack}
                        className="inner-btn"
                        variant="contained"
                        color="inherit"
                      >
                        Back
                      </Button>
                      <Box sx={{ flex: "1 1 auto" }} />
                      <Button type="submit" variant="contained">
                        NEXT
                      </Button>
                    </Box>
                  </div>
                </div>
              </div>
            </div>
          </ValidatorForm>
        );
      case 4:
        return (
          <BookingSummary
            bookingDetails={eventBooking}
            handleComplete={handleComplete}
            event={props.event}
            stripePayment={stripePayment}
            handleClose={handleBack}
            totalAmount={calculateTotalAmount()}
            depositAmount={calculateDepositAmount()}
            handleClosePaymentPopup={handleClosePaymentPopup}
          />
        );
      default:
        return null;
    }
  };

  return (
    <React.Fragment>
      {openConfirmation && (
        <BookingConfirmation
          open={openConfirmation}
          handleClose={handleCloseConfirmationBooking}
          invoice={invoice}
        />
      )}
      <div className="main-iframe">
        <div className="main-div">
          <Card className="main">
            <Box className="main-body">
              <div className="main-header">
                <Stepper
                  nonLinear
                  activeStep={activeStep}
                  alternativeLabel
                  connector={<QontoConnector />}
                  className="stepper"
                >
                  {privateEventBookingSteps.map((label, index) => (
                    <Step key={label} completed={completed[index]}>
                      <StepButton color="inherit">{label}</StepButton>
                    </Step>
                  ))}
                </Stepper>
              </div>
              {renderStepContent(activeStep)}
            </Box>
          </Card>
        </div>
      </div>
    </React.Fragment>
  );
};
const mapDispatchToProps = (dispatch) => ({
  actions: {
    customerAction: bindActionCreators(CustomerAction, dispatch),
  },
});
export default connect(null, mapDispatchToProps)(PrivateEventBooking);

export const INPROGRESS = "CUSTOMER_INPROGRESS";
export const ERROR = "CUSTOMER_ERROR";
export const STOPLOADER = "CUSTOMER_STOPLOADER";
export const GET_COMPANY = "GET_CUSTOMER_COMPANY";
export const GET_COMPANY_OUTLET = "GET_CUSTOMER_COMPANY_OUTLET";
export const SET_COMPANY_OUTLET = "SET_CUSTOMER_COMPANY_OUTLET";
export const GET_RESERVATION_TIMESLOT = "GET_RESERVATION_TIMESLOT";
export const GET_INVOICE_DETAILS = "GET_INVOICE_DETAILS";
export const GET_ALL_TAGS = "GET_ALL_TAGS";
export const UPDATE_STATUS = "UPDATE_STATUS";
export const GET_DINING_OPTION = "GET_DINING_OPTION";
export const GET_TICKET_COMPANY_KEY = "GET_TICKET_COMPANY_KEY";
export const GET_EVENT_BOOKING_TICKET = "GET_EVENT_BOOKING_TICKET";

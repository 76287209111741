import React from "react";
import { Box, Button, Typography } from "@mui/material";
import CheckoutForm from "../../CheckoutForm";
import { parsePhoneNumber } from "libphonenumber-js";

const BookingSummary = ({
  bookingDetails,
  event,
  stripePayment,
  handleClose,
  handleComplete,
  totalAmount,
  depositAmount,
  handleClosePaymentPopup,
}) => {
  const renderInfoItem = (label, value) => (
    <div className="input-box">
      <div className="input-box-inner">
        <Typography variant="h6" fontSize={16}>
          {label}:&nbsp;
        </Typography>
        <Typography variant="h6" fontSize={16}>
          {value}
        </Typography>
      </div>
    </div>
  );

  const renderPhoneNumber = (phoneNumber) => {
    if (!phoneNumber) return "N/A";
    try {
      const parsedNumber = parsePhoneNumber(`+${phoneNumber}`);
      return parsedNumber.formatInternational();
    } catch (error) {
      return phoneNumber;
    }
  };

  return (
    <React.Fragment>
      {stripePayment && (
        <CheckoutForm
          open={true}
          stripePayment={stripePayment}
          handleClosePaymentPopup={handleClosePaymentPopup}
        />
      )}

      <div className="main-iframe">
        <div className="stepper-five stepper-six">
          <h3 className="customer-header" style={{ textAlign: "center" }}>
            Booking Summary
          </h3>

          <div className="conditions-form">
            <div className="input-box w-100">
              <div className="input-box-inner flex-col">
                <Typography variant="h6" fontSize={16}>
                  Restaurant Reservation
                </Typography>
                <Typography variant="h6" fontSize={16}>
                  {event.Outlet.Company.name} &nbsp; {event.Outlet.name}
                </Typography>
              </div>
            </div>

            {renderInfoItem("Date", bookingDetails.date.toLocaleDateString())}
            {renderInfoItem("No. of Adult", bookingDetails.noOfAdult)}
            {renderInfoItem("Time", bookingDetails.exactTime)}
            {renderInfoItem("No. of Child", bookingDetails.noOfChild)}
            {renderInfoItem(
              "Name",
              `${bookingDetails.salutation} ${bookingDetails.name} ${bookingDetails.lastName}`
            )}
            {bookingDetails.occasion &&
              renderInfoItem("Occasion", bookingDetails.occasion)}
            {renderInfoItem("Email", bookingDetails.email)}

            {bookingDetails.dietaryRestriction.length > 0 &&
              renderInfoItem(
                "Dietary Restriction",
                bookingDetails.dietaryRestriction
                  .filter((item) => item.isChecked)
                  .map((item) => item.name)
                  .join(", ")
              )}

            {renderInfoItem(
              "Phone",
              renderPhoneNumber(bookingDetails.mobileNo)
            )}
            {bookingDetails.seatingPreference &&
              renderInfoItem(
                "Seating Preference",
                bookingDetails.seatingPreference
              )}
            {bookingDetails.specialRequest &&
              renderInfoItem("Special Request", bookingDetails.specialRequest)}

            <h3
              className="customer-header w-100 p-10"
              style={{ textAlign: "center" }}
            >
              Additional Request (Deposit Required)
            </h3>

            <div className="input-box w-100 flex-col">
              <div className="input-box w-100">
                <div
                  className="input-box-inner w-100 flex-col"
                  style={{ gap: "5px" }}
                >
                  <div className="input-box w-100">
                    <div className="input-box-inner w-100 flex-space-between p-0">
                      <Typography width={"25%"} variant="h6" fontSize={16}>
                        No. of Adult
                        <br />
                        (Deposit)
                      </Typography>
                      <Typography
                        display={"grid"}
                        justifyContent={"center"}
                        width={"25%"}
                        textAlign={"center"}
                        alignItems={"center"}
                        variant="h6"
                        fontSize={16}
                      >
                        <Typography
                          variant="h6"
                          fontSize={16}
                          sx={{
                            display: "flex",
                            flexDirection: "column!important",
                            alignItems: "flex-end",
                          }}
                        >
                          ${event.amountPerAdult.toFixed(2)}
                        </Typography>
                        <Typography
                          variant="h6"
                          fontSize={16}
                          sx={{
                            display: "flex",
                            flexDirection: "column!important",
                            alignItems: "flex-end",
                          }}
                        >
                          ${event.depositPerAdult.toFixed(2)}
                        </Typography>
                      </Typography>
                      <Typography
                        width={"25%"}
                        textAlign={"center"}
                        variant="h6"
                        fontSize={16}
                      >
                        x{bookingDetails.noOfAdult}
                      </Typography>
                      <Typography
                        width={"25%"}
                        textAlign={"right"}
                        variant="h6"
                        fontSize={16}
                      >
                        $
                        {event.amountPerAdult.toFixed(2) *
                          bookingDetails.noOfAdult}
                        <br />$
                        {event.depositPerAdult.toFixed(2) *
                          bookingDetails.noOfAdult}
                      </Typography>
                    </div>
                  </div>
                  {bookingDetails.noOfChild > 0 && (
                    <div className="input-box w-100">
                      <div className="input-box-inner w-100 flex-space-between p-0">
                        <Typography width={"25%"} variant="h6" fontSize={16}>
                          No. of Child
                          <br />
                          (Deposit)
                        </Typography>
                        <Typography
                          display={"grid"}
                          justifyContent={"center"}
                          width={"25%"}
                          textAlign={"center"}
                          alignItems={"center"}
                          variant="h6"
                          fontSize={16}
                        >
                          <Typography
                            variant="h6"
                            fontSize={16}
                            sx={{
                              display: "flex",
                              flexDirection: "column!important",
                              alignItems: "flex-end",
                            }}
                          >
                            ${event.amountPerChild.toFixed(2)}
                          </Typography>
                          <Typography
                            variant="h6"
                            fontSize={16}
                            sx={{
                              display: "flex",
                              flexDirection: "column!important",
                              alignItems: "flex-end",
                            }}
                          >
                            ${event.depositPerChild.toFixed(2)}
                          </Typography>
                        </Typography>
                        <Typography
                          width={"25%"}
                          textAlign={"center"}
                          variant="h6"
                          fontSize={16}
                        >
                          x{bookingDetails.noOfChild}
                        </Typography>
                        <Typography
                          width={"25%"}
                          textAlign={"right"}
                          variant="h6"
                          fontSize={16}
                        >
                          $
                          {event.amountPerChild.toFixed(2) *
                            bookingDetails.noOfChild}
                          <br />$
                          {event.depositPerChild.toFixed(2) *
                            bookingDetails.noOfChild}
                        </Typography>
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </div>

            <div className="input-box w-100 justify-end">
              <div className="input-box w-50 flex-col">
                <div className="input-box w-100">
                  <div className="input-box-inner w-100 flex-space-between">
                    <Typography variant="h6" fontSize={16}>
                      Total Amount Required
                    </Typography>
                    <Typography variant="h6" fontSize={16}>
                      ${totalAmount.toFixed(2)}
                    </Typography>
                  </div>
                </div>
                <div className="input-box w-100">
                  <div className="input-box-inner w-100 flex-space-between">
                    <Typography variant="h6" fontSize={16}>
                      Deposit
                    </Typography>
                    <Typography variant="h6" fontSize={16}>
                      ${depositAmount.toFixed(2)}
                    </Typography>
                  </div>
                </div>
              </div>
            </div>

            <Box className="footer-btn">
              <Button
                variant="contained"
                className="inner-btn"
                color="inherit"
                onClick={handleClose}
              >
                Back
              </Button>
              <Button variant="contained" onClick={() => handleComplete()}>
                Book now
              </Button>
            </Box>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default BookingSummary;

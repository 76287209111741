export const INPROGRESS = "INPROGRESS";
export const COMPLETE = "COMPLETE";
export const IS_AUTHENTICATED = "IS_AUTHENTICATED";
export const ERROR = "ERROR";
export const LOGOUT = "LOGOUT";
export const GET_USER = "GET_USER";
export const STOPLOADER = "STOPLOADER";
export const RESET_PASSWORD = "RESET_PASSWORD";
export const GET_OUTLETS = "GET_OUTLETS";
export const GET_COMPANYS = "GET_COMPANYS";
export const SET_OUTLET = "SET_OUTLET";
export const SET_COMPANY = "SET_COMPANY";
export const GET_ROLES = "GET_ROLES";
export const GET_USERS = "GET_USERS";
export const UPDATE_ADMIN = "UPDATE_ADMIN";
export const GET_SYSTEM_LOGS = "GET_SYSTEM_LOGS";
export const ADD_ROLE = "ADD_ROLE";
export const UPDATE_ROLE = "UPDATE_ROLE";
export const DELETE_ROLE = "DELETE_ROLE";
export const DELETE_USER = "DELETE_USER";
export const ADD_USER = "ADD_USER";
export const UPDATE_USER = "UPDATE_USER";
export const GET_ALL_ADMIN = "GET_ALL_ADMIN";
export const ADD_ADMIN = "ADD_ADMIN";
export const DELETE_ADMIN = "DELETE_ADMIN";
export const ADD_OUTLET = "ADD_OUTLET";
export const DELETE_OUTLET = "DELETE_OUTLET";
export const UPDATE_OUTLET = "UPDATE_OUTLET";
export const GET_MEALTYPES = "GET_MEALTYPES";
export const UPDATE_MEAL = "UPDATE_MEAL";
export const DELETE_MEAL = "DELETE_MEAL";
export const ADD_MEAL = "ADD_MEAL";
export const GET_ALL_TAGS = "GET_ALL_TAGS";
export const GET_MEAL_TIMINGS = "GET_MEAL_TIMINGS";
export const ADD_MEAL_TIMING = "ADD_MEAL_TIMING";
export const DELETE_MEAL_TIMING = "DELETE_MEAL_TIMING";
export const UPDATE_MEAL_TIME = "UPDATE_MEAL_TIME";
export const ADD_TAG = "ADD_TAG";
export const UPDATE_TAG = "UPDATE_TAG";
export const DELETE_TAG = "DELETE_TAG";
export const GET_ALL_OUTLETTAGS = "GET_ALL_OUTLETTAGS";
export const ADD_OUTLETTAG = "ADD_OUTLETTAG";
export const DELETE_OUTLETTAG = "DELETE_OUTLETTAG";
export const UPDATE_OUTLETTAG = "UPDATE_OUTLETTAG";
export const GET_CLOSURES = "GET_CLOSURES";
export const ADD_CLOSURE = "ADD_CLOSURE";
export const UPDATE_CLOSURE = "UPDATE_CLOSURE;";
export const DELETE_CLOSURE = "DELETE_CLOSURE";
export const GET_PREORDERS = "GET_PREORDERS";
export const ADD_PREORDER = "ADD_PREORDER";
export const DELETE_PREORDER = "DELETE_PREORDER";
export const UPDATE_PREORDER = "UPDATE_PREORDER";
export const GET_TABLE = "GET_TABLE";
export const UPDATE_TABLE = "UPDATE_TABLE";
export const DELETE_TABLE = "DELETE_TABLE";
export const ADD_TABLE = "ADD_TABLE";
export const GET_OUTLET_NOTES = "GET_OUTLET_NOTES";
export const ADD_OUTLET_NOTE = "ADD_OUTLET_NOTE";
export const UPDATE_OUTLET_NOTE = "UPDATE_OUTLET_NOTE";
export const DELETE_OUTLET_NOTE = "DELETE_OUTLET_NOTE";
export const GET_SEATING_TYPE = "GET_SEATING_TYPE";
export const GET_SEAT_TYPE = "GET_SEAT_TYPE";
export const ADD_SEAT_TYPE = "ADD_SEAT_TYPE";
export const UPDATE_SEAT_TYPE = "UPDATE_SEAT_TYPE";
export const DELETE_SEAT_TYPE = "DELETE_SEAT_TYPE";
export const GET_OUTLET_SEATING_INFO = "GET_OUTLET_SEATING_INFO";
export const UPDATE_OUTLET_SEATING_INFO = "UPDATE_OUTLET_SEATING_INFO";
export const UPLOAD_FLOOR_PLAN = "UPLOAD_FLOOR_PLAN";
export const GET_OUTLET_TABLE = "GET_OUTLET_TABLE";
export const ADD_OUTLET_TABLE = "ADD_OUTLET_TABLE";
export const UPDATE_OUTLET_TABLE_POSITION = "UPDATE_OUTLET_TABLE_POSITION";
export const ADD_SEATING_TYPE = "ADD_SEATING_TYPE";
export const UPDATE_SEATING_TYPE = "UPDATE_SEATING_TYPE";
export const DELETE_SEATING_TYPE = "DELETE_SEATING_TYPE";
export const UPDATE_OUTLET_TABLE_INFO = "UPDATE_OUTLET_TABLE_INFO";
export const DELETE_OUTLET_TABLE = "DELETE_OUTLET_TABLE";
export const ADD_TABLE_MERGE = "ADD_TABLE_MERGE";
export const GET_TABLE_MERGE = "GET_TABLE_MERGE";
export const DELETE_TABLE_MERGE = "DELETE_TABLE_MERGE";
export const DELETE_TABLE_MERGE_POSSIBILITY = "DELETE_TABLE_MERGE_POSSIBILITY";
export const ADD_TABLE_MERGE_POSSIBILITY = "ADD_TABLE_MERGE_POSSIBILITY";
export const UPDATE_TABLE_MERGE = "UPDATE_TABLE_MERGE";
export const ADD_TABLE_SECTION = "ADD_TABLE_SECTION";
export const GET_TABLE_SECTION = "GET_TABLE_SECTION";
export const GET_COMPANY = "GET_COMPANY";
export const ADD_COMPANY = "ADD_COMPANY";
export const UPDATE_COMPANY = "UPDATE_COMPANY";
export const DELETE_COMPANY = "DELETE_COMPANY";
export const GET_COMPANY_OUTLET = "GET_COMPANY_OUTLET";
export const ADD_COMPANY_OUTLET = "ADD_COMPANY_OUTLET";
export const DELETE_COMPANY_OUTLET = "DELETE_COMPANY_OUTLET";
export const GET_ALL_CUSTOMER = "GET_ALL_CUSTOMER";
export const GET_CUSTOMER_RESERVATION = "GET_CUSTOMER_RESERVATION";
export const GET_ALL_INVOICE_DETAILS = "GET_ALL_INVOICE_DETAILS";
export const UPDATE_ALL_CUSTOMER = "UPDATE_ALL_CUSTOMER";
export const RESET_OUTLETTABLES = "RESET_OUTLETTABLES";
export const SET_VISIBLE_SELECTION = "SET_VISIBLE_SELECTION";
export const SET_INVISIBLE_SELECTION = "SET_INVISIBLE_SELECTION";
export const SET_VISIBLE_COMPANY_SELECTION = "SET_VISIBLE_COMPANY_SELECTION";
export const SET_INVISIBLE_COMPANY_SELECTION =
  "SET_INVISIBLE_COMPANY_SELECTION";
export const GET_TAG_CATEGORY = "GET_TAG_CATEGORY";
export const ADD_TAG_CATEGORY = "ADD_TAG_CATEGORY";
export const UPDATE_TAG_CATEGORY = "UPDATE_TAG_CATEGORY";
export const DELETE_TAG_CATEGORY = "DELETE_TAG_CATEGORY";
export const GET_BOOKING_TABLE = "GET_BOOKING_TABLE";
export const GET_BOOKING_RESERVATION = "GET_BOOKING_RESERVATION";
export const GET_TIME_SLOT = "GET_TIME_SLOT";
export const UPDATE_INVOICE_STATUS = "UPDATE_INVOICE_STATUS";
export const GET_SEATING_VIEW = "GET_SEATING_VIEW";
export const UPDATE_SEATING_TABLE = "UPDATE_SEATING_TABLE";
export const MOVE_SEATING_RESERVATION = "MOVE_SEATING_RESERVATION";
export const RESET_SEATING_VIEW_TABLES = "RESET_SEATING_VIEW_TABLES";
export const RESET_INVOICE = "RESET_INVOICE";
export const RESET_IVRS = "RESET_IVRS";
export const RESET_CUSTOMER = "RESET_CUSTOMER";
export const RESET_PROFILE = "RESET_PROFILE";
export const GET_COUPON = "GET_COUPON";
export const ADD_COUPON = "ADD_COUPON";
export const UPDATE_COUPON = "UPDATE_COUPON";
export const GET_CUSTOMER_PROFILE = "GET_CUSTOMER_PROFILE";
export const UPDATE_CUSTOMER_RESERVATION = "UPDATE_CUSTOMER_RESERVATION";
export const GET_ALL_MATERIALS = "GET_ALL_MATERIALS";
export const GET_MATERIAL_BY_ID = "GET_MATERIAL_BY_ID";
export const UPDATE_MATERIAL = "UPDATE_MATERIAL";
export const ADD_MATERIALS = "ADD_MATERIALS";
export const DELETE_MATERIAL = "DELETE_MATERIAL";
export const GET_CATEGORY = "GET_CATEGORY";
export const ADD_CATEGORY = "ADD_CATEGORY";
export const UPDATE_CATEGORY = "UPDATE_CATEGORY";
export const DELETE_CATEGORY = "DELETE_CATEGORY";
export const GET_ALL_SUBCATEGORY = "GET_ALL_SUBCATEGORY";
export const ADD_SUBCATEGORY = "ADD_SUBCATEGORY";
export const UPDATE_SUBCATEGORY = "UPDATE_SUBCATEGORY";
export const DELETE_SUBCATEGORY = "DELETE_SUBCATEGORY";
export const GET_DINING_OPTION = "GET_DINING_OPTION";
export const ADD_DINING_OPTION = "ADD_DINING_OPTION";
export const UPDATE_DINING_OPTION = "UPDATE_DINING_OPTION";
export const DELETE_DINING_OPTION = "DELETE_DINING_OPTION";
export const DELETE_TABLE_SECTION = "DELETE_TABLE_SECTION";
export const GET_EMAIL_TEMPLATE = "GET_EMAIL_TEMPLATE";
export const ADD_EMAIL_TEMPLATE = "ADD_EMAIL_TEMPLATE";
export const UPDATE_EMAIL_TEMPLATE = "UPDATE_EMAIL_TEMPLATE";
export const GET_ALL_TICKET = "GET_ALL_TICKET";
export const ADD_TICKET = "ADD_TICKET";
export const UPDATE_TICKET = "UPDATE_TICKET";
export const DELETE_TICKET = "DELETE_TICKET";
export const GET_BOOKING_EVENT = "GET_BOOKING_EVENT";
export const GET_BOOKING_RESERVATION_EVENT = "GET_BOOKING_RESERVATION_EVENT";
export const GET_SMS_TEMPLATE = "GET_SMS_TEMPLATE";
export const ADD_SMS_TEMPLATE = "ADD_SMS_TEMPLATE";
export const UPDATE_SMS_TEMPLATE = "UPDATE_SMS_TEMPLATE";
export const DELETE_SMS_TEMPLATE = "DELETE_SMS_TEMPLATE";
export const GET_IVRS = "GET_IVRS";
export const UPDATE_IVRS = "UPDATE_IVRS";
export const GET_IVRS_INFO = "GET_IVRS_INFO";
export const GET_MARKETING = "GET_MARKETING";
export const ADD_MARKETING = "ADD_MARKETING";
export const UPDATE_MARKETING = "UPDATE_MARKETING";
export const DELETE_MARKETING = "DELETE_MARKETING";
export const UPDATE_INTEGRATION = "UPDATE_INTEGRATION";
export const GET_SYNC_DATA = "GET_SYNC_DATA";
export const GET_TIMETABLE_VIEW = "GET_TIMETABLE_VIEW";
export const GET_MEALTIME_VIEW = "GET_MEALTIME_VIEW";
export const UPDATE_IVRS_CONFIGRATION = "UPDATE_IVRS_CONFIGRATION";
export const GET_USER_ACCESS_MODULES = "GET_USER_ACCESS_MODULES";
export const GET_PERMISSION = "GET_PERMISSION";
export const UPDATE_PERMISSION = "UPDATE_PERMISSION";
export const SET_VISIBLE_DISABLE_OUTLET = "SET_VISIBLE_DISABLE_OUTLET";
export const SET_INVISIBLE_DISABLE_OUTLET = "SET_INVISIBLE_DISABLE_OUTLET";
export const GET_MEALTYPE_BY_COMPANY = "GET_MEALTYPE_BY_COMPANY";
export const GET_MODULE_PERMISSION = "GET_MODULE_PERMISSION";
export const DELETE_EMAIL_TEMPLATE = "DELETE_EMAIL_TEMPLATE";
export const SET_CUSTOMER_CRITERIA = "SET_CUSTOMER_CRITERIA";
export const GET_CUSTOMER_LIST = "GET_CUSTOMER_LIST";
export const SET_VISIBLE_DISABLE_COMPANY = "SET_VISIBLE_DISABLE_COMPANY";
export const SET_INVISIBLE_DISABLE_COMPANY = "SET_INVISIBLE_DISABLE_COMPANY";
export const GET_AUTO_TAGGING = "GET_AUTO_TAGGING";
export const ADD_AUTO_TAGGING = "ADD_AUTO_TAGGING";
export const DELETE_AUTO_TAGGING = "DELETE_AUTO_TAGGING";
export const GET_CUSTOMER_LIST_TAGGING = "GET_CUSTOMER_LIST_TAGGING";
export const GET_ALL_OCCASION = "GET_ALL_OCCASION";
export const GET_ALL_PREORDER = "GET_ALL_PREORDER";
export const GET_ALL_DINNING_OPTION = "GET_ALL_DINNING_OPTION";
export const GET_OUTLET_OCCASION = "GET_OUTLET_OCCASION";
export const UPDATE_TABLE_POSITION = "UPDATE_TABLE_POSITION";
export const GET_OUTLET_TABLE_BY_SEAT_TYPE = "GET_OUTLET_TABLE_BY_SEAT_TYPE";
export const GET_OUTLET_TABLE_BY_GROUP = "GET_OUTLET_TABLE_BY_GROUP";
export const UPDATE_OUTLET_TABLE_BY_GROUP = "UPDATE_OUTLET_TABLE_BY_GROUP";
export const DELETE_OUTLET_TABLE_BY_GROUP = "DELETE_OUTLET_TABLE_BY_GROUP";
export const ADD_OUTLET_TABLE_BY_GROUP = "ADD_OUTLET_TABLE_BY_GROUP";
export const RESET_BOOKING_TABLES = "RESET_BOOKING_TABLES";
export const RESET_SECTION_TABLES = "RESET_SECTION_TABLES";
export const RESET_MERGE_TABLES = "RESET_MERGE_TABLES";
export const GET_CUSTOMER_INVOICE = "GET_CUSTOMER_INVOICE";
export const MANAGE_FLOOR_PLAN = "MANAGE_FLOOR_PLAN";
export const GET_ALL_INVOICE_DETAILS_OF_LISTING_VIEW =
  "GET_ALL_INVOICE_DETAILS_OF_LISTING_VIEW";
export const GET_ALL_REPORT = "GET_ALL_REPORT";
export const GET_ALL_CUSTOMER_REPORT = "GET_ALL_CUSTOMER_REPORT";
export const GET_ALL_CUSTOMER_CROSS_REPORT = "GET_ALL_CUSTOMER_CROSS_REPORT";
export const GET_DIETARY_RESTRICTION_OUTLET_OCCASION =
  "GET_DIETARY_RESTRICTION_OUTLET_OCCASION";
export const RESET_CUSTOMER_RESERVATION = "RESET_CUSTOMER_RESERVATION";
export const RESET_CUSTOMER_LIST_TAGGING = "RESET_CUSTOMER_LIST_TAGGING";
export const RESET_INVOICE_DETAILS = "RESET_INVOICE_DETAILS";
export const GET_MEAL_SESSION_OUTLET = "GET_MEAL_SESSION_OUTLET";
export const GET_COMPANY_MEAL_SESSION = "GET_COMPANY_MEAL_SESSION";
export const GET_CUSTOMER_EVENT_REPORT = "GET_CUSTOMER_EVENT_REPORT";
export const GET_CUSTOMER_SINGLE_RESERVATION =
  "GET_CUSTOMER_SINGLE_RESERVATION";
export const RESET_ALL_SUBCATEGORY = "RESET_ALL_SUBCATEGORY";
export const RESET_CATEGORY = "RESET_CATEGORY";
export const SET_MULTIPLE_COMPANY = "SET_MULTIPLE_COMPANY";
export const SET_VISIBLE_MULTIPLE_COMPANY_SELECTION =
  "SET_VISIBLE_MULTIPLE_COMPANY_SELECTION";
export const SET_INVISIBLE_MULTIPLE_COMPANY_SELECTION =
  "SET_INVISIBLE_MULTIPLE_COMPANY_SELECTION";
export const GET_MARKETING_AUDIENCE_LIST = "GET_MARKETING_AUDIENCE_LIST";
export const GET_ALL_DEITARY_RESTRICTION = "GET_ALL_DEITARY_RESTRICTION";
export const SET_OUTLIST_FOR_DROPDOWN = "SET_OUTLIST_FOR_DROPDOWN";
export const SET_COMPANYLIST_FOR_DROPDOWN = "SET_COMPANYLIST_FOR_DROPDOWN";
export const SET_OUTLET_FROM_CUSTOMER = "SET_OUTLET_FROM_CUSTOMER";
export const UPDATE_TWILIO_CONFIGRATION = "UPDATE_TWILIO_CONFIGRATION";
export const GET_CHARGE_CUSTOMER_INVOICE = "GET_CHARGE_CUSTOMER_INVOICE";
export const GET_ALL_API_LOGS = "GET_ALL_API_LOGS";
export const GET_SYNC_CUSTOMER_PROFILE = "GET_SYNC_CUSTOMER_PROFILE";

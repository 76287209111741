import React from 'react'

const PageNotFound = () => {
  return (
      <div className="page-not-found">
          <img src="/assets/images/404.png" alt='page-not-found'/>
    </div>
  )
}

export default PageNotFound
export const contentLanguage = [
    { id: "ENGLISH", value: "English" },
    { id: "MALAY", value: "Malay" },
    { id: "FRENCH", value: "French" },
    { id: "RUSSIAN", value: "Russian" },
    { id: "CHINESE", value: "chinese" },
    { id: "SPANISH", value: "Spanish" },
  ];




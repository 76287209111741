export const daysOfWeek = [
  {
    code: 0,
    day: "Sunday",
  },
  {
    code: 1,
    day: "Monday",
  },
  {
    code: 2,
    day: "Tuesday",
  },
  {
    code: 3,
    day: "Wednesday",
  },
  {
    code: 4,
    day: "Thursday",
  },
  {
    code: 5,
    day: "Friday",
  },
  {
    code: 6,
    day: "Saturday",
  },
];

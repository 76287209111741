import React, { Fragment, useEffect } from "react";
import { Box, Button, Typography } from "@mui/material";
import { connect } from "react-redux";
import * as CustomerAction from "../../../Action/Customer";
import CheckoutForm from "../CheckoutForm";
import "./style.scss";
import { bindActionCreators } from "redux";
import parsePhoneNumber from "libphonenumber-js";

const ReservationStep6 = (props) => {
  useEffect(() => {
    document.getElementsByClassName("MuiPaper-elevation")[0].scrollTo({
      top: 0,
      behavior: "smooth",
    });
  }, []);

  const calculateTotalAmount = () => {
    let totalDeposit = 0;
    if (props.customerInfo.diningOptions.originalPrice > 0) {
      totalDeposit +=
        props.customerInfo.diningOptions.originalPrice *
        props.customerInfo.diningOptions.qty;
    }

    if (props.customerInfo.basket.filter((item) => item.qty > 0).length > 0) {
      props.customerInfo.basket.forEach((item) => {
        totalDeposit += item.originalPrice * item.qty;
      });
    }

    if (props.customerInfo.privateRoom) {
      totalDeposit += props.customerInfo.privateRoom.originalPrice;
    }

    return totalDeposit.toFixed(2);
  };

  return (
    <React.Fragment>
      {props.stripePayment && (
        <CheckoutForm
          open={true}
          stripePayment={props.stripePayment}
          handleClosePaymentPopup={props.handleClosePaymentPopup}
        />
      )}
      <div className="stepper-five stepper-six">
        <h3
          className="customer-header"
          style={{ display: "flex", justifyContent: "center" }}
        >
          Booking Summary
        </h3>

        <div className="conditions-form">
          <div className="input-box w-100">
            <div className="input-box-inner flex-col">
              <Typography variant="h6" fontSize={16}>
                Restaurant Reservation
              </Typography>
              <Typography variant="h6" fontSize={16}>
                {" "}
                {props.company.name} &nbsp;{" "}
                {
                  props.company.Outlet.find(
                    (outlet) => outlet.id === props.customerInfo.outletId
                  ).name
                }
              </Typography>
            </div>
          </div>

          <div className="input-box">
            <div className="input-box-inner">
              <Typography variant="h6" fontSize={16}>
                Date:&nbsp;
              </Typography>
              <Typography variant="h6" fontSize={16}>
                {props.customerInfo.date.toLocaleDateString()}
              </Typography>
            </div>
          </div>

          <div className="input-box">
            <div className="input-box-inner">
              <Typography variant="h6" fontSize={16}>
                No. of Adult:&nbsp;
              </Typography>
              <Typography variant="h6" fontSize={16}>
                {props.customerInfo.noOfAdult}
              </Typography>
            </div>
          </div>

          <div className="input-box">
            <div className="input-box-inner">
              <Typography variant="h6" fontSize={16}>
                Time:&nbsp;
              </Typography>
              <Typography variant="h6" fontSize={16}>
                {props.customerInfo.exactTime}
              </Typography>
            </div>
          </div>

          <div className="input-box">
            <div className="input-box-inner">
              <Typography variant="h6" fontSize={16}>
                No. of Child:&nbsp;
              </Typography>
              <Typography variant="h6" fontSize={16}>
                {props.customerInfo.noOfChild}
              </Typography>
            </div>
          </div>

          <div className="input-box">
            <div className="input-box-inner">
              <Typography variant="h6" fontSize={16}>
                Name:&nbsp;
              </Typography>
              <Typography variant="h6" fontSize={16}>
                {props.customerInfo.salutation} {props.customerInfo.name}{" "}
                {props.customerInfo.lastName}
              </Typography>
            </div>
          </div>

          {props.customerInfo.occasion && (
            <div className="input-box">
              <div className="input-box-inner">
                <Typography variant="h6" fontSize={16}>
                  Occasion:&nbsp;
                </Typography>
                <Typography variant="h6" fontSize={16}>
                  {props.customerInfo.occasion}
                </Typography>
              </div>
            </div>
          )}

          <div className="input-box">
            <div className="input-box-inner">
              <Typography variant="h6" fontSize={16}>
                Email:&nbsp;
              </Typography>
              <Typography variant="h6" fontSize={16}>
                {props.customerInfo.email}
              </Typography>
            </div>
          </div>

          {props.customerInfo.dietaryRestriction.length > 0 && (
            <div className="input-box">
              <div className="input-box-inner">
                <Typography variant="h6" fontSize={16}>
                  Dietary Restriction:&nbsp;
                </Typography>
                <Typography variant="h6" fontSize={16}>
                  {props.customerInfo.dietaryRestriction
                    .map((item) => item.name)
                    .join(", ")}
                </Typography>
              </div>
            </div>
          )}

          <div className="input-box">
            <div className="input-box-inner">
              <Typography variant="h6" fontSize={16}>
                Phone:&nbsp;
              </Typography>
              <Typography variant="h6" fontSize={16}>
                {props.customerInfo.mobileNo
                  ? parsePhoneNumber(`+${props.customerInfo.mobileNo}`)
                    ? parsePhoneNumber(
                        `+${props.customerInfo.mobileNo}`
                      ).formatInternational()
                    : props.customerInfo.mobileNo
                  : "N/A"}
              </Typography>
            </div>
          </div>

          {props.customerInfo.seatingPreference && (
            <div className="input-box">
              <div
                className="input-box-inner update-checkbox"
                style={{ gap: "10px" }}
              >
                <Typography variant="h6" fontSize={16}>
                  Seating Preference:&nbsp;
                </Typography>
                <Typography variant="h6" fontSize={16}>
                  {props.customerInfo.seatingPreference}
                </Typography>
              </div>
            </div>
          )}

          {props.customerInfo.specialRequest && (
            <div className="input-box">
              <div
                className="input-box-inner update-checkbox"
                style={{ gap: "10px" }}
              >
                <Typography variant="h6" fontSize={16}>
                  Special Request:&nbsp;
                </Typography>
                <Typography variant="h6" fontSize={16}>
                  {props.customerInfo.specialRequest}
                </Typography>
              </div>
            </div>
          )}

          {props.customerInfo.customerNote && (
            <div className="input-box">
              <div
                className="input-box-inner update-checkbox"
                style={{ gap: "10px" }}
              >
                <Typography variant="h6" fontSize={16}>
                  Note:&nbsp;
                </Typography>
                <Typography variant="h6" fontSize={16}>
                  {props.customerInfo.customerNote}
                </Typography>
              </div>
            </div>
          )}

          {props.customerInfo.dinningAmount > 0 && (
            <Fragment>
              <h3
                className="customer-header w-100 p-10"
                style={{ display: "flex", justifyContent: "center" }}
              >
                Additional Request (Deposit Required)
              </h3>

              <div className="input-box w-100 flex-col">
                {props.customerInfo.diningOptions.originalPrice > 0 && (
                  <div className="input-box w-100">
                    <div
                      className="input-box-inner w-100 flex-col"
                      style={{ gap: "5px" }}
                    >
                      <Typography
                        width={"50%"}
                        variant="h6"
                        fontSize={18}
                        fontWeight={"bold"}
                      >
                        Dining Option
                      </Typography>
                      <div className="input-box w-100">
                        <div className="input-box-inner w-100 flex-space-between p-0">
                          <Typography width={"25%"} variant="h6" fontSize={16}>
                            {props.customerInfo.diningOptions.name} &nbsp;
                            <br />
                            (Deposit)
                          </Typography>
                          <Typography
                            display={"grid"}
                            justifyContent={"center"}
                            width={"25%"}
                            textAlign={"center"}
                            alignItems={"center"}
                            variant="h6"
                            fontSize={16}
                          >
                            <Typography
                              variant="h6"
                              fontSize={16}
                              sx={{
                                display: "flex",
                                flexDirection: "column!important",
                                alignItems: "flex-end",
                              }}
                            >
                              {" "}
                              $
                              {props.customerInfo.diningOptions.originalPrice.toFixed(
                                2
                              )}
                            </Typography>
                            <Typography
                              variant="h6"
                              fontSize={16}
                              sx={{
                                display: "flex",
                                flexDirection: "column!important",
                                alignItems: "flex-end",
                              }}
                            >
                              {" "}
                              $
                              {props.customerInfo.diningOptions.price.toFixed(
                                2
                              )}
                            </Typography>
                          </Typography>
                          <Typography
                            width={"25%"}
                            textAlign={"center"}
                            variant="h6"
                            fontSize={16}
                          >
                            x{props.customerInfo.diningOptions.qty}
                          </Typography>
                          <Typography
                            width={"25%"}
                            textAlign={"right"}
                            variant="h6"
                            fontSize={16}
                          >
                            $
                            {props.customerInfo.diningOptions.qty *
                              props.customerInfo.diningOptions.originalPrice}
                            <br />$
                            {props.customerInfo.diningOptions.qty *
                              props.customerInfo.diningOptions.price}
                          </Typography>
                        </div>
                      </div>
                    </div>
                  </div>
                )}

                {props.customerInfo.basket.filter((item) => item.qty > 0)
                  .length > 0 && (
                  <div className="input-box w-100 ">
                    <div
                      className="input-box-inner w-100 flex-col "
                      style={{ gap: "5px" }}
                    >
                      <Typography
                        width={"50%"}
                        variant="h6"
                        fontSize={18}
                        fontWeight={"bold"}
                      >
                        Pre-Order Item
                      </Typography>
                      {props.customerInfo.basket
                        .filter((item) => item.qty > 0)
                        .map((item, index) => (
                          <div key={index} className="input-box w-100">
                            <div className="input-box-inner w-100 flex-space-between p-0">
                              <Typography
                                width={"25%"}
                                variant="h6"
                                fontSize={16}
                              >
                                {item.name} &nbsp;
                                <br />
                                (Deposit)
                              </Typography>
                              <Typography
                                display={"grid"}
                                justifyContent={"center"}
                                width={"25%"}
                                textAlign={"center"}
                                alignItems={"center"}
                                variant="h6"
                                fontSize={16}
                              >
                                <Typography
                                  variant="h6"
                                  fontSize={16}
                                  sx={{
                                    display: "flex",
                                    flexDirection: "column!important",
                                    alignItems: "flex-end",
                                  }}
                                >
                                  {" "}
                                  ${item.originalPrice.toFixed(2)}
                                </Typography>
                                <Typography
                                  variant="h6"
                                  fontSize={16}
                                  sx={{
                                    display: "flex",
                                    flexDirection: "column!important",
                                    alignItems: "flex-end",
                                  }}
                                >
                                  {" "}
                                  ${item.price.toFixed(2)}{" "}
                                </Typography>
                              </Typography>

                              <Typography
                                width={"25%"}
                                textAlign={"center"}
                                variant="h6"
                                fontSize={16}
                              >
                                x{item.qty}
                              </Typography>
                              <Typography
                                width={"25%"}
                                textAlign={"right"}
                                variant="h6"
                                fontSize={16}
                              >
                                ${item.qty * item.originalPrice}
                                <br />${item.qty * item.price}
                              </Typography>
                            </div>
                          </div>
                        ))}
                    </div>
                  </div>
                )}

                {props.customerInfo.privateRoom && (
                  <div className="input-box w-100">
                    <div
                      className="input-box-inner w-100 flex-col"
                      style={{ gap: "5px" }}
                    >
                      <Typography
                        width={"50%"}
                        variant="h6"
                        fontSize={18}
                        fontWeight={"bold"}
                      >
                        Private Room
                      </Typography>
                      <div className="input-box w-100">
                        <div className="input-box-inner w-100 flex-space-between p-0">
                          <Typography width={"25%"} variant="h6" fontSize={16}>
                            {props.customerInfo.privateRoom.name} &nbsp;
                            <br />
                            (Deposit)
                          </Typography>
                          <Typography
                            display={"grid"}
                            justifyContent={"center"}
                            width={"25%"}
                            textAlign={"center"}
                            alignItems={"center"}
                            variant="h6"
                            fontSize={16}
                          >
                            <Typography
                              variant="h6"
                              fontSize={16}
                              sx={{
                                display: "flex",
                                flexDirection: "column!important",
                                alignItems: "flex-end",
                              }}
                            >
                              {" "}
                              ${" "}
                              {props.customerInfo.privateRoom.originalPrice.toFixed(
                                2
                              )}
                            </Typography>
                            <Typography
                              variant="h6"
                              fontSize={16}
                              sx={{
                                display: "flex",
                                flexDirection: "column!important",
                                alignItems: "flex-end",
                              }}
                            >
                              {" "}
                              ${props.customerInfo.privateRoom.price.toFixed(
                                2
                              )}{" "}
                            </Typography>
                          </Typography>
                          <Typography
                            width={"25%"}
                            textAlign={"center"}
                            variant="h6"
                            fontSize={16}
                          >
                            x
                            {props.customerInfo.privateRoom
                              ? 1
                              : props.customerInfo.privateRoom.qty}
                          </Typography>
                          <Typography
                            width={"25%"}
                            textAlign={"right"}
                            variant="h6"
                            fontSize={16}
                          >
                            $
                            {props.customerInfo.privateRoom.originalPrice.toFixed(
                              2
                            )}
                            <br />$
                            {props.customerInfo.privateRoom.price.toFixed(2)}
                          </Typography>
                        </div>
                      </div>
                    </div>
                  </div>
                )}
              </div>

              <div
                className="input-box w-100 justify-end"
                style={{ marginTop: "20px" }}
              >
                <div className="input-box w-50 flex-col">
                  <div className="input-box w-100">
                    <div className="input-box-inner w-100 flex-space-between">
                      <Typography variant="h6" fontSize={16}>
                        Total Amount
                      </Typography>
                      <Typography variant="h6" fontSize={16}>
                        ${calculateTotalAmount()}
                      </Typography>
                    </div>
                  </div>

                  <div className="input-box w-100">
                    <div className="input-box-inner w-100 flex-space-between">
                      <Typography variant="h6" fontSize={16}>
                        Total Deposit Required
                      </Typography>
                      <Typography variant="h6" fontSize={16}>
                        ${props.customerInfo.dinningAmount}
                      </Typography>
                    </div>
                  </div>
                </div>
              </div>
            </Fragment>
          )}

          <div className="condition-info w-100 mt-20">
            <span className="conditions-info-text w-100">Notice</span>
            <div className="conditions w-100">
              {props.customerInfo.dinningAmount > 0 && (
                <span
                  style={{ whiteSpace: "pre-wrap" }}
                  className="notices-text"
                >
                  {props.company.bookingSummaryPN}
                </span>
              )}
              {props.customerInfo.dinningAmount < 1 && (
                <span
                  style={{ whiteSpace: "pre-wrap" }}
                  className="notices-text"
                >
                  {props.company.bookingSummaryNoPN}
                </span>
              )}
            </div>
          </div>

          <Box className="footer-btn">
            {!props.stripePayment && (
              <Button
                variant="contained"
                className="inner-btn"
                color="inherit"
                disabled={props.activeStep === 0}
                onClick={props.handleBack}
              >
                Back
              </Button>
            )}
            {!props.stripePayment && (
              <Button
                onClick={props.handleComplete}
                type="submit"
                variant="contained"
              >
                BOOK NOW
              </Button>
            )}
          </Box>
        </div>
      </div>
    </React.Fragment>
  );
};

const mapDispatchToProps = (dispatch) => ({
  actions: {
    customerAction: bindActionCreators(CustomerAction, dispatch),
  },
});

export default connect(null, mapDispatchToProps)(ReservationStep6);

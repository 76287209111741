import { getAccessToken, getUserDetail } from "../../utils/";
const accessToken = getAccessToken();

let token = null;
let userDetail = null;
if (accessToken !== "undefined" && accessToken !== null) {
  token = accessToken;
  userDetail = getUserDetail();
}

const initialData = {
  hotelReducer: {
    loading: false,
    error_msg: null,
    success_msg: null,
    token: token,
    userDetail: userDetail,
    outlets: [],
    companies: [],
    roles: [],
    selectedOutlet: null,
    selectedCompany: null,
    users: [],
    superUsers: [],
    mealTypes: [],
    systemLogs: [],
    tags: [],
    mealTimings: [],
    outletTags: [],
    closures: [],
    preorders: [],
    outletNotes: [],
    seatingType: [],
    seatType: [],
    tables: [],
    outletSeatingInfo: null,
    outletTables: [],
    mergedTables: [],
    sectionTables: [],
    companyOutlets: [],
    customers: [],
    invoicesCharge: [],
    customerReservationData: [],
    invoiceDetails: [],
    apiLogs: [],
    isVisibleOutletSelection: true,
    isVisibleCompanySelection: false,
    isVisibleMultipleCompanySelection: false,
    isVisibleOutlet: false,
    isVisibleCompany: false,
    tagCategories: [],
    timeSlots: [],
    bookingTables: [],
    bookingReservations: [],
    seatingView: [],
    coupons: [],
    couponsTimeSlotDetails: null,
    profiles: null,
    materials: [],
    categories: [],
    subCategories: [],
    diningOptions: [],
    emailTemplates: [],
    tickets: [],
    smsTemplates: [],
    ivrs: [],
    marketing: [],
    customerList: [],
    customerListTagging: [],
    companyOccasion: [],
    ivrsInfo: [],
    timeline: [],
    mealTime: [],
    IvrsConfigration: [],
    outletOccasion: [],
    outletDietaryRestriction: [],
    preOrderItems: [],
    permission: null,
    mealTypeCompany: [],
    dinningOptions: [],
    modulePermissions: null,
    selectedCustomerCriteria: null,
    autoTagging: [],
    tablePosition: [],
    outletTablesBySeatType: [],
    outletTablesByGroup: [],
    showFloorPlan: true,
    reports: null,
    invoiceDetailsOfListingView: [],
    customerReports: null,
    eventReports: null,
    customerCrossReports: null,
    eventSingleReservation: null,
    invoices: null,
    mealSession: [],
    companyMealSession: [],
    allCompanies: [],
    chunkRisk: 0,
    multipleCompanies: [],
    marketingAudienceList: [],
    dietaryRestiction: [],
    /* initial state of all outlet of user with all module */
    moduleWisePermission: null,
    outletForDropDown: [],
  },
};

export default initialData;

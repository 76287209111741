const initialData = {
  customerReducer: {
    loading: false,
    error_msg: null,
    success_msg: null,
    companies: [],
    selectedOutlet: null,
    companyOutlets: [],
    events: null,
    reservationTimeSlot: null,
    invoiceDetails: null,
    tags: [],
    diningOptions: [],
    eventReservations: [],
    company: null,
  },
};

export default initialData;
